import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DefaultApi} from '../service/default.api';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Provider} from '../model/Provider';
import {FormBuilder} from '@angular/forms';
import {RecaptchaComponent, ReCaptchaV3Service} from 'ng-recaptcha';
import {CookieService} from 'ngx-cookie-service';
import {Subscription} from 'rxjs';
import {SocialLoginComponent} from '../social-login/social-login.component';

@Component({
  selector: 'app-plugin-list',
  templateUrl: './plugin-list.component.html',
  styleUrls: ['./plugin-list.component.scss']
})
export class PluginListComponent implements OnInit, OnDestroy {
  private providers: Provider[];
  private captchaResponse;
  public loginForm;
  public loading = false;
  public error = '';
  credentials = {username: '', textMessage: '', captcha: ''};
  private subscription: Subscription;
  // @ViewChild(RecaptchaComponent, {static: false}) captcha: RecaptchaComponent;

  constructor(private api: DefaultApi, private cookieService: CookieService/*, private recaptchaV3Service: ReCaptchaV3Service*/) {

  }

  ngOnInit() {
    console.log('_-----------------' + SocialLoginComponent.isRedirection);
    // this.api.getProviders().subscribe((value: Provider[]) => this.providers = value);
  }

  getProviders() {
    return this.providers;
  }

  handleCorrectCaptcha(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

  onSubmit() {
    /*this.subscription = this.recaptchaV3Service.execute('signin')
      .subscribe(value => {
        this.credentials.captcha = value;
        console.log(this.credentials);

        this.loading = true;
        this.api.login(this.credentials)
          .subscribe(
            (response) => {
              this.loading = false;
              this.error = '';
              console.log(response);
              // this.cookieService.set('token', response.accessToken, new Date(Date.now() + 200000), '/', 'ezfrwrd.so.com', false, "None");

              // console.log((new Date(Date.now())).toLocaleString());
              // console.log((new Date(Date.now() + 200000)).toLocaleString());
              // this.cookieService.set('token', '1', new Date(Date.now() + 200000));
              console.log(this.cookieService.get('token'));
              window.location.href = '/auth/finish';
            },
            (response) => {
              this.loading = false;
              this.error = response.statusText;
            }
          );
      });
*/
  }
  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  btnSocialLoginClick(providerLink) {
    /*this.api.socialLogin(providerLink).subscribe(
      (response) => {
        let url = window.URL.createObjectURL(response);
        window.location.href = url;
      }
    )*/
    window.location.href = 'http://localhost:8080/auth/socialLogin/facebook/';
  }
  isRedirection() {
    return SocialLoginComponent.isRedirection;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {PluginListComponent} from './plugin-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module} from 'ng-recaptcha';



@NgModule({
  declarations: [
    PluginListComponent,

  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RecaptchaV3Module,
    ReactiveFormsModule

  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdtRs8UAAAAANaYU-hhW9hI_UoITvAP7W-gsrYz' },
  ],
  exports: [
    PluginListComponent,

  ]
})
export class PluginListModule { }

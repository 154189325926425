import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'home',
  styleUrls: [ './home.component.css' ],
  templateUrl: './home.component.html'
})
export class Home implements OnInit {
  localState = { value: '' };
  constructor(//public appState: AppState,
              private route: ActivatedRoute,
              //private roleManager: RoleManager,
              //private pluginManager: PluginManager,
              private router: Router/*,
              private api: DefaultApi*/) {

  }

  ngOnInit() {
    console.log('hello `Home` component');
    /*this.route.queryParams.subscribe((params) => {
      this.processQueryParams(params);
    });
    this.appState.set('idp_state', 'plugins');

    this.api.logger();*/
    // this.title.getData().subscribe(data => this.data = data);
  }

  /*processQueryParams(params) {
    if (params.admin !== undefined) {
      this.appState.set('admin', true);
    }
    let role = this.appState.get('admin') ? RoleEnum.ADMINISTRATOR : this.roleManager.currentRole;
    this.appState.set('role_send', true);
    if (this.appState.get('admin')) {
      this.roleManager.setCurrent(RoleEnum.ADMINISTRATOR);
      this.pluginManager.resetCurrent();
      // this.router.navigate(['/', { queryParams: {admin: true}}]);
    }
    this.api.postSelectedRole(role)
        .then((response: any) => {
          console.info(`Send selected role: ${role} to server. ${response}`);
        });
  }

  submitState(value: string) {
    console.log('submitState', value);
    this.appState.set('value', value);
    this.localState.value = '';
  }*/
}

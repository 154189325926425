import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {DefaultApi} from './service/default.api';
import {PluginListModule} from './plugin-list/plugin-list.module';
import {CookieService} from 'ngx-cookie-service';
import {Router, RouterModule} from '@angular/router';
import {ROUTES} from './app.routes';
import {Home} from './home';
import {RedirectionComponent} from './redirection';
import {NoContent} from './no-content';
import {SocialLoginAuthModule} from './social-login';
import {AuthInterceptor} from './interceptors/AuthInterceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    Home,
    RedirectionComponent,
    NoContent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PluginListModule,
    SocialLoginAuthModule,
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      // preloadingStrategy: PreloadAllModules
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [Router]
    },
    DefaultApi,
    CookieService,
    {
      provide: 'authUrl',
      useValue: 'auth/sso/'
    }
  ],
  entryComponents: [Home, RedirectionComponent]
})
export class AppModule { }

import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {RedirectionComponent} from '../redirection';



@Component({
    selector: 'socialLogin',
    templateUrl: './social-login.component.html'
})
export class SocialLoginComponent implements OnInit {

    constructor(
                private route: ActivatedRoute,
                private router: Router) {
    }
    static isRedirection = false;

    errorMessage = '';
    pluginName = 'SocialLoginComponent';

  getRedirection() {
    return SocialLoginComponent.isRedirection;
  }

    ngOnInit() {
      console.log('ngOnInit `social-login` component');
      this.route.queryParams.subscribe((params) => {
            // console.log(params, 'params from redirect');
          this.processQueryParams(params);
        });
    }

    onEsiaButtonClicked() {
        window.location.href = `rs/${this.pluginName}/`;
    }

    processQueryParams(params) {
        SocialLoginComponent.isRedirection = !!params.plugin;
        console.log('params.success = ' + params.success);
        if (!!params.option) {
            this.redirectToOption(params.option);
        } else if (params.success === 'P') { // login as Person
            this.router.navigate(['/options'], { queryParams: {
                plugin: this.pluginName
            }});
        } else if (params.success === 'PF') { // show person info edit form
            RedirectionComponent.redirectToRegPerson();
        } else if (params.success === 'OF') { // show organization info edit form
          RedirectionComponent.redirectToRegOrg();
        } else if (params.success === 'O') { // login as Org
          RedirectionComponent.redirectToFinish();
        } else if (!!params.error) {
            this.errorMessage = `Во время авторизации произошла ошибка ${params.error}`;
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 3000);
        }
    }

    redirectToOption(opt) {
        window.location.href = `rs/${this.pluginName}/${opt}/`;
    }


}

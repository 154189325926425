import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
//import { map } from 'rxjs-compat/operator/map';
import {Provider} from "../model/Provider";
import {map} from "rxjs/operators";
import {CookieService} from "ngx-cookie-service";


@Injectable()
export class DefaultApi {



  constructor(public http: HttpClient, private cookieService: CookieService){

  }

  getProviders(){
     return this.http.get<any>('/auth/providers')
       .pipe(map((value: any[]) => value.map(provider => {
         return new Provider({
           providerName: provider.providerName,
           providerLink: provider.providerLink
         })
       })));

  }

  login(credentials: any/*, response: string*/) {
    const data = {
      userEmail: credentials.username.trim(),
      textMessage: credentials.textMessage.trim(),
      captcha: credentials.captcha
    };

    return this.http.post<any>('/auth/login', data, {headers: this.getHeaders()});
  }

  socialLogin(uri) {
    const url = `/auth/socialLogin/facebook/`;
    const headers = new HttpHeaders({
      Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    });
    return this.http.get(url);
  }
  private getHeaders() {
    return  new HttpHeaders ({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }
}

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SocialLoginComponent } from './social-login.component';


@NgModule({
    declarations: [
      SocialLoginComponent
    ],
    imports: [
        CommonModule,
        FormsModule
    ]
})
export class SocialLoginAuthModule {
}

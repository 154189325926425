interface ProviderParameters {
  providerName: string;
  providerLink: string
}

export class Provider {
  public providerName;
  public providerLink;
  public providerIcon;
  constructor({providerName, providerLink}: ProviderParameters) {
    this.providerName = providerName;
    this.providerLink = providerLink;
    this.providerIcon = providerName.toLowerCase();
  }

}

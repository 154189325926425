import {Injectable, Injector} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";

import {CookieService} from "ngx-cookie-service";
import {catchError, finalize} from "rxjs/operators";
import Swal from "sweetalert2";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor( private cookieService: CookieService) {
    console.log('!!!!!!!!!!!!create!!!!!!!!!!!!!');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('!!!!!!!!!!!!intercept!!!!!!!!!!!!!');
    return next.handle(request).pipe(
      catchError(err => {
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!!');
        if (err instanceof HttpErrorResponse) {

          if (err.status === 401 || err.status === 403) {
            Swal.fire({
              title: 'Error',
              text: err.message,
              confirmButtonColor: '#F34254',
              icon: 'error'
            }).then(value => {
              window.location.href = '/public';
            });
          }
          else if (err.status === 412) {
            console.log('!!!!!!!!!!!!!412!!!!!!!!!!!!');
            Swal.fire({
              title: 'Error',
              text: err.headers.get('error'),
              confirmButtonColor: '#F34254',
              icon: 'error'
            })
          };
        }
        return throwError(err);
      }),
      finalize(() => {
        // any cleanup or final activities
      })
    );
  }
}

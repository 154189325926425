import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';



const signUpUrl = '/idp/signup/';

@Component({
    selector: 'redirection',
    templateUrl: './redirection.component.html'
})
export class RedirectionComponent implements OnInit {

    loading = true;
    errorMessage = '';

    static redirectToFinish() {
        window.location.href = '/auth/finish';
    }

    static redirectToRegOrg() {
        window.location.href = `${signUpUrl}#/organization`;
    }

    static redirectToRegPerson() {
        window.location.href = `${signUpUrl}#/person`;
    }

    static redirectToAgreement() {
        window.location.href = `${signUpUrl}#/rules?fromOptions=true`;
    }

    static redirectToTempProfilePerson(login) {
        window.location.href = `${signUpUrl}#/temp?login=${login}`;
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router) {

    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.processQueryParams(params);
        });
        console.log('ngOnInit in `Redirection` component');
    }

    processQueryParams(params) {
        if (!!params.plugin) {
            this.router.navigate([`/${params.plugin}`], {queryParams: params});
        } else if (!!params.option) {
            window.location.href = `rs/esia/${params.option}/`; // todo rewrite
        } else if (!!params.error) {
            this.errorMessage = `Во время авторизации произошла ошибка ${params.error}`;
            setTimeout(() => {
                this.router.navigate(['/']);
            }, 3000);
        }
    }

}



import { Routes, RouterModule } from '@angular/router';
import { Home } from './home';
import {RedirectionComponent} from './redirection';
import {NoContent} from './no-content';
import {SocialLoginComponent} from './social-login/social-login.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {PluginListComponent} from './plugin-list/plugin-list.component';


const ROUTES: Routes = [
    {path: '', component: PluginListComponent},
    {path: 'home', component: Home},
    {path: 'redirection', component: RedirectionComponent},
    {path: 'socialLogin', component: SocialLoginComponent},
    {path: 'error',
      loadChildren: './error-page/index#ErrorPageAuthModule'
    },
    {path: '**', component: NoContent},
];

export { ROUTES };
